import { MutationResult } from '@apollo/client'
import React from 'react'
import { useForm, Controller } from 'react-hook-form'

import {
  Scalars,
  UpdatePublisherMutation,
  useNewsCategoriesQuery,
} from '../graphql/graphql'
import { Checkbox } from './Checkbox'
import { ErrorMessage } from './ErrorMessage'
import { FileInput } from './FileInput'

type Input = {
  id: string
  name: string
  kana: string
  zipcode: string
  address: string
  tel: string
  fax: string
  logo?: Scalars['Upload']
  removeLogo?: boolean
  transactionCode?: string
  publisherPrefix?: string
  fromPersonUnit?: string
  personName: string
  personMail: string
  copyright?: string
  url?: string
  jpro?: boolean | null
  linkagePersonName: string
  linkagePersonMail: string
  description?: string
  catchphrase?: string
  amazonAssociatesId?: string
  rakutenAffiliateId?: string
  sevenAndYUrl?: string
  googleAnalyticsTag?: string
  contactMail: string
  bannerBigStatus?: boolean | null
  yondemillBookSales?: boolean | null
  twitter?: string
  facebook?: string
  instagram?: string
  ogpTitle?: string
  ogpDescription?: string
  ogpImage?: Scalars['Upload']
  removeOgpImage?: boolean
  authUid?: string
  lpNewsCategoryId?: string
  newsPublicUrlStatus?: boolean | null
  shopifyStorefrontAccessToken?: string
  ownerId?: string
  ogimageStatus?: boolean | null
}

type FormValues = Input & {
  logoUrl?: string | null
  ogpImageUrl?: string | null
}

type Props = {
  defaultValues?: FormValues
  result: MutationResult<UpdatePublisherMutation>
  isAdmin?: boolean
  addPage?: boolean
  hasShopifyStore?: boolean
  onSubmit(values: Input): void
  onSendToShopifyButtonClick?(): void
}

export const PublisherForm: React.FC<Props> = ({
  defaultValues,
  result,
  isAdmin,
  addPage,
  hasShopifyStore,
  children,
  onSubmit,
  onSendToShopifyButtonClick,
}) => {
  const { register, handleSubmit, control, watch } = useForm<Input>()
  const formSubmit = handleSubmit(onSubmit)
  let newsCategoriesQuery: any
  if (defaultValues?.id) {
    newsCategoriesQuery = useNewsCategoriesQuery({
      variables: { publisherId: defaultValues?.id },
    })
  }
  const watchJpro = watch('jpro', defaultValues?.jpro)
  return (
    <>
      {watchJpro && (
        <div className="main-card mb-3 card">
          <div className="card-body">
            <p className="mb-0">
              <b>連携項目</b>
              <br />
              <span className="badge badge-dot badge-dot-sm badge-jpro ml-1">
                JPRO
              </span>
              ：JPRO
              <br />
            </p>
          </div>
        </div>
      )}
      <form onSubmit={formSubmit}>
        <div className="main-card mb-3 card">
          <div className="card-body">
            <div className="row form-group align-items-center">
              <label className="col-md-2 col-form-label font-weight-bold">
                会社コード
              </label>
              <div className="col-auto">
                <input
                  placeholder=""
                  type="text"
                  className="form-control"
                  {...(isAdmin ? register('id') : {})}
                  defaultValue={defaultValues?.id}
                  readOnly
                />
                <ErrorMessage
                  errorMessages={
                    result.error?.graphQLErrors[0]?.extensions?.errors?.id ?? []
                  }
                />
              </div>
            </div>
            <div className="divider" />
            <div className="row form-group align-items-center">
              <label
                htmlFor="nameInput"
                className="col-md-2 col-form-label font-weight-bold"
              >
                <span className="badge badge-danger mr-2">必須</span>会社名
                {watchJpro && (
                  <span className="badge badge-dot badge-dot-sm badge-jpro ml-1">
                    JPRO
                  </span>
                )}
              </label>
              <div className="col-md-10">
                <input
                  id="nameInput"
                  placeholder=""
                  type="text"
                  className="form-control"
                  {...register('name')}
                  defaultValue={defaultValues?.name}
                />
                <ErrorMessage
                  errorMessages={
                    result.error?.graphQLErrors[0]?.extensions?.errors?.name ??
                    []
                  }
                />
              </div>
            </div>
            <div className="divider" />
            <div className="row form-group align-items-center">
              <label
                htmlFor="kanaInput"
                className="col-md-2 col-form-label font-weight-bold"
              >
                <span className="badge badge-danger mr-2">必須</span>会社名カナ
              </label>
              <div className="col-md-10">
                <input
                  id="kanaInput"
                  placeholder=""
                  type="text"
                  className="form-control"
                  {...register('kana')}
                  defaultValue={defaultValues?.kana}
                />
                <ErrorMessage
                  errorMessages={
                    result.error?.graphQLErrors[0]?.extensions?.errors?.kana ??
                    []
                  }
                />
                <small className="form-text w-100">
                  ※全角カタカナでご入力ください。
                </small>
              </div>
            </div>
            <div className="divider" />
            <div className="row form-group align-items-center">
              <label
                htmlFor="zipcodeInput"
                className="col-md-2 col-form-label font-weight-bold"
              >
                <span className="badge badge-danger mr-2">必須</span>
                会社郵便番号
              </label>
              <div className="col-auto">
                <input
                  id="zipcodeInput"
                  placeholder=""
                  type="text"
                  className="form-control"
                  {...register('zipcode')}
                  defaultValue={defaultValues?.zipcode}
                />
                <ErrorMessage
                  errorMessages={
                    result.error?.graphQLErrors[0]?.extensions?.errors
                      ?.zipcode ?? []
                  }
                />
              </div>
            </div>
            <div className="divider" />
            <div className="row form-group align-items-center">
              <label
                htmlFor="addressInput"
                className="col-md-2 col-form-label font-weight-bold"
              >
                <span className="badge badge-danger mr-2">必須</span>会社住所
              </label>
              <div className="col-md-10">
                <textarea
                  id="addressInput"
                  className="form-control"
                  {...register('address')}
                  defaultValue={defaultValues?.address}
                />
                <ErrorMessage
                  errorMessages={
                    result.error?.graphQLErrors[0]?.extensions?.errors
                      ?.address ?? []
                  }
                />
              </div>
            </div>
            <div className="divider" />
            <div className="row form-group align-items-center">
              <label
                htmlFor="telInput"
                className="col-md-2 col-form-label font-weight-bold"
              >
                <span className="badge badge-danger mr-2">必須</span>
                会社電話番号
                {watchJpro && (
                  <span className="badge badge-dot badge-dot-sm badge-jpro ml-1">
                    JPRO
                  </span>
                )}
              </label>
              <div className="col-auto">
                <input
                  id="telInput"
                  placeholder=""
                  type="text"
                  className="form-control"
                  {...register('tel')}
                  defaultValue={defaultValues?.tel}
                />
                <ErrorMessage
                  errorMessages={
                    result.error?.graphQLErrors[0]?.extensions?.errors?.tel ??
                    []
                  }
                />
              </div>
            </div>
            <div className="divider" />
            <div className="row form-group align-items-center">
              <label
                htmlFor="faxInput"
                className="col-md-2 col-form-label font-weight-bold"
              >
                会社FAX番号
              </label>
              <div className="col-auto">
                <input
                  id="faxInput"
                  placeholder=""
                  type="text"
                  className="form-control"
                  {...register('fax')}
                  defaultValue={defaultValues?.fax}
                />
                <ErrorMessage
                  errorMessages={
                    result.error?.graphQLErrors[0]?.extensions?.errors?.fax ??
                    []
                  }
                />
              </div>
            </div>
            <div className="divider" />
            <div className="row form-group align-items-center">
              <label className="col-md-2 col-form-label font-weight-bold">
                ロゴ
              </label>
              <div className="col-auto">
                {defaultValues?.logoUrl && (
                  <div className="mb-3">
                    <img src={defaultValues?.logoUrl} />
                    <div className="custom-control custom-checkbox mt-1">
                      <Checkbox {...register('removeLogo')}>削除する</Checkbox>
                    </div>
                  </div>
                )}
                <Controller
                  control={control}
                  name="logo"
                  defaultValue={null}
                  render={({ field: { onChange } }) => (
                    <FileInput
                      name="logo"
                      type="file"
                      accept="image/png,image/jpeg,image/gif,image/svg+xml,image/webp"
                      className="form-control-file"
                      onChange={(files) => {
                        if (files && files.length > 0) {
                          onChange(files[0])
                        }
                      }}
                    />
                  )}
                />
                <ErrorMessage
                  errorMessages={
                    result.error?.graphQLErrors[0]?.extensions?.errors?.logo ??
                    []
                  }
                />
              </div>
            </div>
            <div className="divider" />
            <div className="row form-group align-items-center">
              <label
                htmlFor="transactionCodeInput"
                className="col-md-2 col-form-label font-weight-bold"
              >
                取引コード
                {watchJpro && (
                  <span className="badge badge-dot badge-dot-sm badge-jpro ml-1">
                    JPRO
                  </span>
                )}
              </label>
              <div className="col-auto">
                <input
                  id="transactionCodeInput"
                  placeholder=""
                  type="text"
                  className="form-control"
                  {...register('transactionCode')}
                  defaultValue={defaultValues?.transactionCode}
                />
                <ErrorMessage
                  errorMessages={
                    result.error?.graphQLErrors[0]?.extensions?.errors
                      ?.transactionCode ?? []
                  }
                />
              </div>
            </div>
            <div className="divider" />
            <div className="row form-group align-items-center">
              <label
                htmlFor="publisherPrefixInput"
                className="col-md-2 col-form-label font-weight-bold"
              >
                {watchJpro && (
                  <span className="badge badge-danger mr-2">必須</span>
                )}
                ISBN 出版社記号
                {watchJpro && (
                  <span className="badge badge-dot badge-dot-sm badge-jpro ml-1">
                    JPRO
                  </span>
                )}
              </label>
              <div className="col-auto">
                <input
                  id="publisherPrefixInput"
                  placeholder=""
                  type="text"
                  className="form-control"
                  {...register('publisherPrefix')}
                  defaultValue={defaultValues?.publisherPrefix}
                />
                <small className="form-text w-100">
                  ※複数ある場合は半角セミコロンでつなげて入力してください。
                </small>
                <ErrorMessage
                  errorMessages={
                    result.error?.graphQLErrors[0]?.extensions?.errors
                      ?.publisherPrefix ?? []
                  }
                />
              </div>
            </div>
            <div className="divider" />
            <div className="row form-group align-items-center">
              <label
                htmlFor="fromPersonUnitInput"
                className="col-md-2 col-form-label font-weight-bold"
              >
                {watchJpro && (
                  <span className="badge badge-danger mr-2">必須</span>
                )}
                部署
                {watchJpro && (
                  <span className="badge badge-dot badge-dot-sm badge-jpro ml-1">
                    JPRO
                  </span>
                )}
              </label>
              <div className="col-md-10">
                <input
                  id="fromPersonUnitInput"
                  placeholder=""
                  type="text"
                  className="form-control"
                  {...register('fromPersonUnit')}
                  defaultValue={defaultValues?.fromPersonUnit}
                />
                <ErrorMessage
                  errorMessages={
                    result.error?.graphQLErrors[0]?.extensions?.errors
                      ?.fromPersonUnit ?? []
                  }
                />
              </div>
            </div>
            <div className="divider" />
            <div className="row form-group align-items-center">
              <label
                htmlFor="personNameInput"
                className="col-md-2 col-form-label font-weight-bold"
              >
                <span className="badge badge-danger mr-2">必須</span>担当者
              </label>
              <div className="col-md-10">
                <input
                  id="personNameInput"
                  placeholder=""
                  type="text"
                  className="form-control"
                  {...register('personName')}
                  defaultValue={defaultValues?.personName}
                />
                <ErrorMessage
                  errorMessages={
                    result.error?.graphQLErrors[0]?.extensions?.errors
                      ?.personName ?? []
                  }
                />
              </div>
            </div>
            <div className="divider" />
            <div className="row form-group align-items-center">
              <label
                htmlFor="personMailInput"
                className="col-md-2 col-form-label font-weight-bold"
              >
                <span className="badge badge-danger mr-2">必須</span>
                担当者メールアドレス
              </label>
              <div className="col-md-10">
                <input
                  id="personMailInput"
                  placeholder=""
                  type="text"
                  className="form-control"
                  {...register('personMail')}
                  defaultValue={defaultValues?.personMail}
                />
                <ErrorMessage
                  errorMessages={
                    result.error?.graphQLErrors[0]?.extensions?.errors
                      ?.personMail ?? []
                  }
                />
              </div>
            </div>
            <div className="divider" />
            <div className="row form-group align-items-center">
              <label
                htmlFor="copyrightInput"
                className="col-md-2 col-form-label font-weight-bold"
              >
                Copyright
              </label>
              <div className="col-md-10">
                <input
                  id="copyrightInput"
                  placeholder=""
                  type="text"
                  className="form-control"
                  {...register('copyright')}
                  defaultValue={defaultValues?.copyright}
                />
                <ErrorMessage
                  errorMessages={
                    result.error?.graphQLErrors[0]?.extensions?.errors
                      ?.copyright ?? []
                  }
                />
              </div>
            </div>
            <div className="divider" />
            <div className="row form-group align-items-center">
              <label
                htmlFor="urlInput"
                className="col-md-2 col-form-label font-weight-bold"
              >
                <span className="badge badge-danger mr-2">必須</span>
                URL
              </label>
              <div className="col-md-10">
                <input
                  id="urlInput"
                  placeholder=""
                  type="text"
                  className="form-control"
                  readOnly={!isAdmin}
                  {...(isAdmin ? register('url') : {})}
                  defaultValue={defaultValues?.url}
                />
                <ErrorMessage
                  errorMessages={
                    result.error?.graphQLErrors[0]?.extensions?.errors?.url ??
                    []
                  }
                />
                {isAdmin && (
                  <small className="form-text w-100">
                    ※独自ドメインをご利用の場合は、http://で始まるURLをご入力ください。URLは/で終わる必要があります。
                  </small>
                )}
              </div>
            </div>
            <div className="divider" />
            <div className="row form-group align-items-center">
              <label
                htmlFor="jproSelect"
                className="col-md-2 col-form-label font-weight-bold"
              >
                JPRO連携
              </label>
              <div className="col-auto">
                <select
                  id="jproSelect"
                  className="custom-select form-control w-auto"
                  disabled={!isAdmin}
                  {...(isAdmin
                    ? register('jpro', {
                        setValueAs: (value) => value === 'true',
                      })
                    : {})}
                  defaultValue={defaultValues?.jpro ? 'true' : 'false'}
                >
                  <option value="false">無効</option>
                  <option value="true">有効</option>
                </select>
              </div>
            </div>
            <div className="divider" />
            <div className="row form-group align-items-center">
              <label
                htmlFor="linkagePersonNameInput"
                className="col-md-2 col-form-label font-weight-bold"
              >
                <span className="badge badge-danger mr-2">必須</span>
                データ連携担当者名
                {watchJpro && (
                  <span className="badge badge-dot badge-dot-sm badge-jpro ml-1">
                    JPRO
                  </span>
                )}
              </label>
              <div className="col-md-10">
                <input
                  id="linkagePersonNameInput"
                  placeholder=""
                  type="text"
                  className="form-control"
                  {...register('linkagePersonName')}
                  defaultValue={defaultValues?.linkagePersonName}
                />
                <ErrorMessage
                  errorMessages={
                    result.error?.graphQLErrors[0]?.extensions?.errors
                      ?.linkagePersonName ?? []
                  }
                />
              </div>
            </div>
            <div className="divider" />
            <div className="row form-group align-items-center">
              <label
                htmlFor="linkagePersonMailInput"
                className="col-md-2 col-form-label font-weight-bold"
              >
                <span className="badge badge-danger mr-2">必須</span>
                データ連携担当者メールアドレス
              </label>
              <div className="col-md-10">
                <input
                  id="linkagePersonMailInput"
                  placeholder=""
                  type="text"
                  className="form-control"
                  {...register('linkagePersonMail')}
                  defaultValue={defaultValues?.linkagePersonMail}
                />
                <ErrorMessage
                  errorMessages={
                    result.error?.graphQLErrors[0]?.extensions?.errors
                      ?.linkagePersonMail ?? []
                  }
                />
              </div>
            </div>
            <div className="divider" />
            <div className="row form-group align-items-center">
              <label
                htmlFor="descriptionInput"
                className="col-md-2 col-form-label font-weight-bold"
              >
                会社説明
              </label>
              <div className="col-md-10">
                <input
                  id="descriptionInput"
                  placeholder=""
                  type="text"
                  className="form-control"
                  {...register('description')}
                  defaultValue={defaultValues?.description}
                />
                <ErrorMessage
                  errorMessages={
                    result.error?.graphQLErrors[0]?.extensions?.errors
                      ?.description ?? []
                  }
                />
              </div>
            </div>
            <div className="divider" />
            <div className="row form-group align-items-center">
              <label
                htmlFor="catchphraseInput"
                className="col-md-2 col-form-label font-weight-bold"
              >
                キャッチフレーズ
              </label>
              <div className="col-md-10">
                <input
                  id="catchphraseInput"
                  placeholder=""
                  type="text"
                  className="form-control"
                  {...register('catchphrase')}
                  defaultValue={defaultValues?.catchphrase}
                />
                <ErrorMessage
                  errorMessages={
                    result.error?.graphQLErrors[0]?.extensions?.errors
                      ?.catchphrase ?? []
                  }
                />
                <small className="form-text w-100">
                  ※25文字以内でご入力ください。
                </small>
              </div>
            </div>
            <div className="divider" />
            <div className="row form-group align-items-center">
              <label
                htmlFor="amazonAssociatesIdInput"
                className="col-md-2 col-form-label font-weight-bold"
              >
                アフィリエイト（amazon）ID
              </label>
              <div className="col-auto">
                <input
                  id="amazonAssociatesIdInput"
                  placeholder=""
                  type="text"
                  className="form-control"
                  {...(isAdmin ? register('amazonAssociatesId') : {})}
                  readOnly={!isAdmin}
                  defaultValue={defaultValues?.amazonAssociatesId}
                />
                <ErrorMessage
                  errorMessages={
                    result.error?.graphQLErrors[0]?.extensions?.errors
                      ?.amazonAssociatesId ?? []
                  }
                />
              </div>
            </div>
            <div className="divider" />
            <div className="row form-group align-items-center">
              <label
                htmlFor="rakutenAffiliateIdInput"
                className="col-md-2 col-form-label font-weight-bold"
              >
                アフィリエイト（楽天）ID
              </label>
              <div className="col-auto">
                <input
                  id="rakutenAffiliateIdInput"
                  placeholder=""
                  type="text"
                  className="form-control"
                  {...(isAdmin ? register('rakutenAffiliateId') : {})}
                  readOnly={!isAdmin}
                  defaultValue={defaultValues?.rakutenAffiliateId}
                />
                <ErrorMessage
                  errorMessages={
                    result.error?.graphQLErrors[0]?.extensions?.errors
                      ?.rakutenAffiliateId ?? []
                  }
                />
              </div>
            </div>
            <div className="divider" />
            <div className="row form-group align-items-center">
              <label
                htmlFor="sevenAndYUrlInput"
                className="col-md-2 col-form-label font-weight-bold"
              >
                7&amp;Y用アフィリエイト基本URL
              </label>
              <div className="col-md-10">
                <input
                  id="sevenAndYUrlInput"
                  placeholder=""
                  type="text"
                  className="form-control"
                  {...(isAdmin ? register('sevenAndYUrl') : {})}
                  readOnly={!isAdmin}
                  defaultValue={defaultValues?.sevenAndYUrl}
                />
                <ErrorMessage
                  errorMessages={
                    result.error?.graphQLErrors[0]?.extensions?.errors
                      ?.sevenAndYUrl ?? []
                  }
                />
              </div>
            </div>
            <div className="divider" />
            <div className="row form-group align-items-center">
              <label
                htmlFor="googleAnalyticsTagInput"
                className="col-md-2 col-form-label font-weight-bold"
              >
                Google Analytics用タグ
              </label>
              <div className="col-md-10">
                <textarea
                  id="googleAnalyticsTagInput"
                  className="form-control"
                  {...(isAdmin ? register('googleAnalyticsTag') : {})}
                  readOnly={!isAdmin}
                  defaultValue={defaultValues?.googleAnalyticsTag}
                />
                <ErrorMessage
                  errorMessages={
                    result.error?.graphQLErrors[0]?.extensions?.errors
                      ?.googleAnalyticsTag ?? []
                  }
                />
              </div>
            </div>
            <div className="divider" />
            <div className="row form-group align-items-center">
              <label
                htmlFor="contactMailInput"
                className="col-md-2 col-form-label font-weight-bold"
              >
                <span className="badge badge-danger mr-2">必須</span>
                お問い合わせ用メールアドレス
              </label>
              <div className="col-md-10">
                <input
                  id="contactMailInput"
                  placeholder=""
                  type="text"
                  className="form-control"
                  {...register('contactMail')}
                  defaultValue={defaultValues?.contactMail}
                />
                <ErrorMessage
                  errorMessages={
                    result.error?.graphQLErrors[0]?.extensions?.errors
                      ?.contactMail ?? []
                  }
                />
              </div>
            </div>
            <div className="divider" />
            <div className="row form-group align-items-center">
              <label
                htmlFor="bannerBigStatusSelect"
                className="col-md-2 col-form-label font-weight-bold"
              >
                ビッグバナー利用
              </label>
              <div className="col-auto">
                <select
                  id="bannerBigStatusSelect"
                  className="custom-select form-control w-auto"
                  disabled={!isAdmin}
                  {...(isAdmin
                    ? register('bannerBigStatus', {
                        setValueAs: (value) => value === 'true',
                      })
                    : {})}
                  defaultValue={
                    defaultValues?.bannerBigStatus ? 'true' : 'false'
                  }
                >
                  <option value="false">無効</option>
                  <option value="true">有効</option>
                </select>
              </div>
            </div>
            {!addPage && (
              <>
                <div className="divider" />
                <div className="row form-group align-items-center">
                  <label
                    htmlFor="lpNewsCategoryIdInput"
                    className="col-md-2 col-form-label font-weight-bold"
                  >
                    LP機能：お知らせカテゴリID
                  </label>
                  <div className="col-auto">
                    <select
                      className="custom-select custom-select-sm form-control"
                      {...(isAdmin
                        ? register('lpNewsCategoryId', {
                            setValueAs(v) {
                              if (v) return v
                              else if (v === undefined)
                                return defaultValues?.lpNewsCategoryId
                            },
                          })
                        : {})}
                      disabled={!isAdmin}
                    >
                      <option value="" />
                      {newsCategoriesQuery.data?.publisher?.newsCategories.map(
                        (category: any) => (
                          <option
                            key={category.id}
                            value={category.id}
                            selected={
                              category.id == defaultValues?.lpNewsCategoryId
                            }
                          >
                            {'　'.repeat(category.depth)}
                            {category.name}
                          </option>
                        )
                      )}
                    </select>
                    <ErrorMessage
                      errorMessages={
                        result.error?.graphQLErrors[0]?.extensions?.errors
                          ?.lpNewsCategoryId ?? []
                      }
                    />
                    <small className="form-text w-100">
                      ※未選択：LP機能無効
                      <br />
                      ※選択　：LP機能有効
                      LP機能を適応させるお知らせカテゴリを選択
                    </small>
                  </div>
                </div>
                <div className="divider" />
                <div className="row form-group align-items-center">
                  <label
                    htmlFor="newsPublicUrlStatusSelect"
                    className="col-md-2 col-form-label font-weight-bold"
                  >
                    記事公開URL指定機能
                  </label>
                  <div className="col-auto">
                    <select
                      id="newsPublicUrlStatusSelect"
                      className="custom-select form-control w-auto"
                      disabled={!isAdmin}
                      {...(isAdmin
                        ? register('newsPublicUrlStatus', {
                            setValueAs: (value) => value === 'true',
                          })
                        : {})}
                      defaultValue={
                        defaultValues?.newsPublicUrlStatus ? 'true' : 'false'
                      }
                    >
                      <option value="false">無効</option>
                      <option value="true">有効</option>
                    </select>
                    <small className="form-text w-100">
                      ※LP記事でのみ使用できます。
                    </small>
                  </div>
                </div>
                <div className="divider" />
                <div className="row form-group align-items-center">
                  <label
                    htmlFor="ogimageStatusSelect"
                    className="col-md-2 col-form-label font-weight-bold"
                  >
                    書誌ページOGP画像生成
                  </label>
                  <div className="col-auto">
                    <select
                      id="ogimageStatusSelect"
                      className="custom-select form-control w-auto"
                      disabled={!isAdmin}
                      {...(isAdmin
                        ? register('ogimageStatus', {
                            setValueAs: (value) => value === 'true',
                          })
                        : {})}
                      defaultValue={
                        defaultValues?.ogimageStatus ? 'true' : 'false'
                      }
                    >
                      <option value="false">無効</option>
                      <option value="true">有効</option>
                    </select>
                  </div>
                </div>
              </>
            )}
            <div className="divider" />
            <div className="row form-group align-items-center">
              <label
                htmlFor="yondemillBookSalesSelect"
                className="col-md-2 col-form-label font-weight-bold"
              >
                YONDEMILL 販売システム連携
              </label>
              <div className="col-auto">
                <select
                  id="yondemillBookSalesSelect"
                  className="custom-select form-control"
                  disabled={!isAdmin}
                  {...(isAdmin
                    ? register('yondemillBookSales', {
                        setValueAs: (value) => value === 'true',
                      })
                    : {})}
                  defaultValue={
                    defaultValues?.yondemillBookSales ? 'true' : 'false'
                  }
                >
                  <option value="false">無効</option>
                  <option value="true">有効</option>
                </select>
                <ErrorMessage
                  errorMessages={
                    result.error?.graphQLErrors[0]?.extensions?.errors
                      ?.yondemillBookSales ?? []
                  }
                />
              </div>
            </div>
            <div className="divider" />
            <div className="row form-group align-items-center">
              <label
                htmlFor="authUidInput"
                className="col-md-2 col-form-label font-weight-bold"
              >
                Shopify連携
              </label>
              <div className="col-auto">
                <div className="form-inline">
                  <input
                    id="authUidInput"
                    placeholder=""
                    type="text"
                    className="form-control mr-1"
                    {...(isAdmin ? register('authUid') : {})}
                    readOnly={!isAdmin}
                    defaultValue={defaultValues?.authUid}
                  />
                  {hasShopifyStore && (
                    <>
                      <span className="text-success">
                        <i className="lnr-checkmark-circle mx-1" />
                        連携済み
                      </span>
                      {onSendToShopifyButtonClick != null && (
                        <button
                          type="button"
                          className="btn btn-sm btn-light text-nowrap ml-2"
                          data-toggle="modal"
                          data-target=".bd-shopify-modal-sm"
                          onClick={(e) => {
                            e.preventDefault()
                            onSendToShopifyButtonClick()
                          }}
                        >
                          <span>全商品をShopifyに連携</span>
                        </button>
                      )}
                    </>
                  )}
                </div>
                <ErrorMessage
                  errorMessages={
                    result.error?.graphQLErrors[0]?.extensions?.errors
                      .authUid ?? []
                  }
                />
              </div>
            </div>
            {!addPage && (
              <>
                <div className="divider" />
                <div className="row form-group align-items-center">
                  <label
                    htmlFor="shopifyStorefrontAccessTokenInput"
                    className="col-md-2 col-form-label font-weight-bold"
                  >
                    Shopify ストアフロント アクセストークン
                  </label>
                  <div className="col-md-10">
                    <input
                      id="shopifyStorefrontAccessTokenInput"
                      placeholder=""
                      type="text"
                      className="form-control"
                      {...(isAdmin
                        ? register('shopifyStorefrontAccessToken')
                        : {})}
                      readOnly={!isAdmin}
                      defaultValue={defaultValues?.shopifyStorefrontAccessToken}
                    />
                    <ErrorMessage
                      errorMessages={
                        result.error?.graphQLErrors[0]?.extensions?.errors
                          ?.shopifyStorefrontAccessToken ?? []
                      }
                    />
                  </div>
                </div>
              </>
            )}
            {process.env.FLB_SHOPIFY_ENABLED === 'true' && (
              <>
                <div className="divider" />
                <div className="row form-group align-items-center">
                  <label
                    htmlFor="ownerIdInput"
                    className="col-md-2 col-form-label font-weight-bold"
                  >
                    HONDANA電子書籍連携
                  </label>
                  <div className="col-auto">
                    <input
                      id="ownerIdInput"
                      placeholder=""
                      type="text"
                      className="form-control"
                      {...(isAdmin ? register('ownerId') : {})}
                      readOnly={!isAdmin}
                      defaultValue={defaultValues?.ownerId}
                    />
                    <small className="form-text w-100">
                      ※連携するFLB
                      ROOTのコンテンツオーナーのIDを入力してください。
                    </small>
                    <ErrorMessage
                      errorMessages={
                        result.error?.graphQLErrors[0]?.extensions?.errors
                          ?.ownerId ?? []
                      }
                    />
                  </div>
                </div>
              </>
            )}
            <div className="divider" />
            <div className="row form-group align-items-center">
              <label
                htmlFor="twitterInput"
                className="col-md-2 col-form-label font-weight-bold"
              >
                Twitterアカウント
              </label>
              <div className="col-auto">
                <div className="form-inline">
                  https://twitter.com/
                  <input
                    id="twitterInput"
                    placeholder=""
                    type="text"
                    className="form-control mx-1"
                    {...register('twitter')}
                    defaultValue={defaultValues?.twitter}
                  />
                  /
                </div>
                <ErrorMessage
                  errorMessages={
                    result.error?.graphQLErrors[0]?.extensions?.errors
                      ?.twitter ?? []
                  }
                />
              </div>
            </div>
            <div className="divider" />
            <div className="row form-group align-items-center">
              <label
                htmlFor="facebookInput"
                className="col-md-2 col-form-label font-weight-bold"
              >
                Facebookページ
              </label>
              <div className="col-auto">
                <div className="form-inline">
                  https://www.facebook.com/
                  <input
                    id="facebookInput"
                    placeholder=""
                    type="text"
                    className="form-control mx-1"
                    {...register('facebook')}
                    defaultValue={defaultValues?.facebook}
                  />
                  /
                </div>
                <ErrorMessage
                  errorMessages={
                    result.error?.graphQLErrors[0]?.extensions?.errors
                      ?.facebook ?? []
                  }
                />
              </div>
            </div>
            <div className="divider" />
            <div className="row form-group align-items-center">
              <label
                htmlFor="instagramInput"
                className="col-md-2 col-form-label font-weight-bold"
              >
                Instagramアカウント
              </label>
              <div className="col-auto">
                <div className="form-inline">
                  https://www.instagram.com/
                  <input
                    id="instagramInput"
                    placeholder=""
                    type="text"
                    className="form-control mx-1"
                    {...register('instagram')}
                    defaultValue={defaultValues?.instagram}
                  />
                  /
                </div>
                <ErrorMessage
                  errorMessages={
                    result.error?.graphQLErrors[0]?.extensions?.errors
                      ?.instagram ?? []
                  }
                />
              </div>
            </div>
            <div className="divider" />
            <div className="row form-group align-items-center">
              <label
                htmlFor="ogpTitleInput"
                className="col-md-2 col-form-label font-weight-bold"
              >
                OGP Title
              </label>
              <div className="col-md-10">
                <input
                  id="ogpTitleInput"
                  placeholder=""
                  type="text"
                  className="form-control"
                  {...register('ogpTitle')}
                  defaultValue={defaultValues?.ogpTitle}
                />
                <ErrorMessage
                  errorMessages={
                    result.error?.graphQLErrors[0]?.extensions?.errors
                      ?.ogpTitle ?? []
                  }
                />
              </div>
            </div>
            <div className="divider" />
            <div className="row form-group align-items-center">
              <label
                htmlFor="ogpDescriptionInput"
                className="col-md-2 col-form-label font-weight-bold"
              >
                OGP Description
              </label>
              <div className="col-md-10">
                <input
                  id="ogpDescriptionInput"
                  placeholder=""
                  type="text"
                  className="form-control"
                  {...register('ogpDescription')}
                  defaultValue={defaultValues?.ogpDescription}
                />
                <ErrorMessage
                  errorMessages={
                    result.error?.graphQLErrors[0]?.extensions?.errors
                      ?.ogpDescription ?? []
                  }
                />
              </div>
            </div>
            <div className="divider" />
            <div className="row form-group align-items-center">
              <label className="col-md-2 col-form-label font-weight-bold">
                OGP Image
              </label>
              <div className="col-auto">
                {defaultValues?.ogpImageUrl && (
                  <div className="mb-3">
                    <img src={defaultValues?.ogpImageUrl} />
                    <div className="custom-control custom-checkbox mt-1">
                      <Checkbox {...register('removeOgpImage')}>
                        削除する
                      </Checkbox>
                    </div>
                  </div>
                )}
                <Controller
                  control={control}
                  name="ogpImage"
                  defaultValue={null}
                  render={({ field: { onChange } }) => (
                    <FileInput
                      name="ogpImage"
                      type="file"
                      accept="image/png,image/jpeg,image/gif,image/webp"
                      className="form-control-file"
                      onChange={(files) => {
                        if (files && files.length > 0) {
                          onChange(files[0])
                        }
                      }}
                    />
                  )}
                />
                <ErrorMessage
                  errorMessages={
                    result.error?.graphQLErrors[0]?.extensions?.errors
                      ?.ogpImage ?? []
                  }
                />
              </div>
            </div>
            <div className="divider" />
            {children}
            <div className="text-center">
              <button className="btn btn-primary btn-lg btn-wide">登録</button>
            </div>
          </div>
        </div>
      </form>
    </>
  )
}
